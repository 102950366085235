<template>
  <router-link
    :to="`/service/${order.id}`"
    class="boards-container__board__card"
  >
    <div v-if="order.priority_id > 0">
      <p class="label" :style="`color: ${order.priority.colour}`">
        {{ order.priority.name }}
      </p>
    </div>
    <div class="wrap">
      <div>
        <p>Numer zlecenia</p>
        <h5 class="board-title">{{ order.number }}</h5>
      </div>
    </div>
    <div class="wrap">
      <div>
        <p>Klient</p>
        <h5 class="board-title">{{ order.customer_name }}</h5>
      </div>
    </div>
    <div class="wrap" v-if="order.device_name || order.device_service_work">
      <div v-if="order.device_name">
        <p>{{ dictionary["table_order_device_name_field_label"] }}</p>
        <h5 class="board-title">{{ order.device_name }}</h5>
      </div>
      <div v-if="order.device_service_work">
        <p>{{ dictionary["table_order_device_service_work_field_label"] }}</p>
        <h5 class="board-title" v-html="deviceServiceWork" />
      </div>
    </div>
    <div class="handle">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 1024 1024"
      >
        <path
          fill="currentColor"
          d="M909.3 506.3L781.7 405.6a7.23 7.23 0 0 0-11.7 5.7V476H548V254h64.8c6 0 9.4-7 5.7-11.7L517.7 114.7a7.14 7.14 0 0 0-11.3 0L405.6 242.3a7.23 7.23 0 0 0 5.7 11.7H476v222H254v-64.8c0-6-7-9.4-11.7-5.7L114.7 506.3a7.14 7.14 0 0 0 0 11.3l127.5 100.8c4.7 3.7 11.7.4 11.7-5.7V548h222v222h-64.8c-6 0-9.4 7-5.7 11.7l100.8 127.5c2.9 3.7 8.5 3.7 11.3 0l100.8-127.5c3.7-4.7.4-11.7-5.7-11.7H548V548h222v64.8c0 6 7 9.4 11.7 5.7l127.5-100.8a7.3 7.3 0 0 0 .1-11.4"
        />
      </svg>
    </div>
  </router-link>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "OrdersKanbanItem",
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      dictionary: "dictionary",
      status: "auth/status",
    }),
    deviceServiceWork() {
      return this.order.device_service_work
        ? this.order.device_service_work.replace(/\n/g, "<br>")
        : "";
    },
  },
  methods: {
    getStatus(id) {
      return this.status.find((item) => item.id === id);
    },
  },
};
</script>

<style scoped>
.boards-container__board__card {
  background: #fff;
  box-shadow: 0 1px 0 rgba(9, 30, 66, 0.25);
  border-radius: 3px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
}

.wrap {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
}

.board-title {
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
}

.handle {
  position: absolute;
  top: 0;
  right: 0;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: move;
}

@media (max-width: 991px) {
  .ym-toptitle .title {
    font-size: 16px;
  }
}

@media (min-width: 992px) {
  .ml-md-auto {
    margin-left: auto;
  }
  .text-md-right {
    text-align: right;
  }
}
</style>
