<template>
  <div
    v-if="dictionary && Object.keys(dictionary).length > 0"
    class="ym-whitebg mb-24"
    style="padding: 16px"
  >
    <div v-if="isEdit">
      <ValidationObserver v-slot="{ handleSubmit, errors }">
        <form @submit.prevent="handleSubmit(onOrderNoteSubmit)">
          <div style="margin-bottom: 16px">
            <label for="f_company_full">{{
              dictionary["table_order_notes_add_field_label"]
            }}</label>
            <ValidationProvider rules="required" name="note_note">
              <textarea
                name="note_note"
                v-model="order_note.note"
                class="input is-medium"
              />
            </ValidationProvider>
            <span
              v-if="errors['note_note'] && errors['note_note'].length > 0"
              class="help is-danger"
              >{{ errors["note_note"][0] }}</span
            >
          </div>
          <div style="margin-bottom: 16px">
            <label for="note_service_date">{{
              dictionary["table_order_notes_service_date_field_label"]
            }}</label>
            <ValidationProvider rules="" name="note_service_date">
              <b-datepicker
                :mobile-native="false"
                name="note_service_date"
                v-model="order_note.service_date"
                placeholder="Wybierz"
                icon="calendar-today"
                :first-day-of-week="1"
                trap-focus
              >
              </b-datepicker>
            </ValidationProvider>
            <small style="margin-top: 8px">
              {{
                dictionary["table_order_notes_service_date_name_field_label"]
              }}:
              <b>{{ getDayName(order_note.service_date) }}</b>
            </small>
          </div>
          <div style="margin-bottom: 16px">
            <label for="note_service_date">{{
              dictionary["table_order_notes_service_time_start_end_field_label"]
            }}</label>
            <div class="columns is-multiline">
              <div class="column is-12-mobile is-2-tablet">
                <div class="field">
                  <div class="control is-medium">
                    <ValidationProvider rules="" name="service_time_start">
                      <b-timepicker
                        v-model="order_note.service_time_start"
                        inline
                        style="background-color: transparent; box-shadow: none"
                      ></b-timepicker>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
              <div class="column is-12-mobile is-2-tablet">
                <div class="field">
                  <div class="control is-medium">
                    <ValidationProvider rules="" name="service_time_end">
                      <b-timepicker
                        v-model="order_note.service_time_end"
                        inline
                        style="background-color: transparent; box-shadow: none"
                      ></b-timepicker>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <label for="note_service_date">{{
              dictionary["table_order_note_technical_ids_field_label"]
            }}</label>
            <ValidationProvider rules="" name="note_technical_ids">
              <multiselect
                name="note_technical_ids"
                v-model="order_note.technical_ids"
                :options="filteredTechnicals"
                :multiple="true"
                :group-select="false"
                placeholder="Wyszukaj"
                selectLabel="Wybierz"
                track-by="fullname"
                :label="`fullname`"
                class="bigger"
              >
                <template slot="singleLabel" slot-scope="props">
                  <div class="option__title">
                    {{ props.option.fullname }}
                  </div>
                </template>
                <template slot="option" slot-scope="props">
                  <div class="option__title">
                    {{ props.option.fullname }}
                  </div>
                </template>
              </multiselect>
            </ValidationProvider>
          </div>
          <!-- 
          <div>
            <label for="note_service_time"
              >{{ dictionary["table_order_notes_service_time_field_label"] }}
              <small>(00:00:00 (hh:mm:ss))</small></label
            >
            <ValidationProvider rules="" name="note_service_time">
              <input
                name="note_service_time"
                placeholder="00:00:00"
                v-maska="'##:##:##'"
                type="text"
                v-model="order_note.service_time"
                class="input is-medium is-uppercase"
              />
            </ValidationProvider>
          </div>
		-->
          <div style="display: flex; align-items: center; margin-top: 16px">
            <div style="display: flex; gap: 8px; margin-left: auto">
              <button @click="toggleEdit" class="button is-small is-secondary">
                <span>zamknij</span>
              </button>
              <button class="button is-small is-primary">
                <span>zapisz</span>
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
    <div v-else>
      <label style="display: flex"
        >{{ dictionary["table_order_notes_note_field_label"] }} dodana przez:
        {{ note.user.firstname + " " + note.user.lastname }}
        <small style="margin-left: auto"
          >Stworzona: {{ note.created_at }}</small
        ></label
      >
      <div class="note">
        <p>{{ note.note }}</p>
      </div>
      <div class="data">
        <div>
          <p>{{ dictionary["table_order_notes_service_date_field_label"] }}</p>
          <b
            >{{ note.service_date }}
            <small>({{ getDayName(note.service_date) }})</small></b
          >
        </div>
        <div>
          <p>
            {{
              dictionary["table_order_notes_service_time_start_end_field_label"]
            }}
          </p>
          <b
            >{{ formatWorkHour(note.service_time_start) }} -
            {{ formatWorkHour(note.service_time_end) }}</b
          >
        </div>
        <div v-if="noteTechnicals && noteTechnicals.length > 0">
          <p>{{ dictionary["table_order_note_technical_ids_field_label"] }}</p>
          <div v-for="technical in noteTechnicals" :key="technical.id">
            <b>{{ technical.firstname + " " + technical.lastname }}</b>
          </div>
        </div>
      </div>
      <div style="display: flex; align-items: center; margin-top: 16px">
        <p v-if="note.service_time !== '00:00:00'">
          {{ dictionary["table_order_notes_service_time_field_label"] }}:
          <b>{{ note.service_time }}</b>
        </p>
        <div style="display: flex; gap: 8px; margin-left: auto">
          <button @click="removeNote" class="button is-small is-secondary">
            <icon name="trash"></icon><span>Usuń</span>
          </button>
          <button @click="toggleEdit" class="button is-small is-secondary">
            <icon name="edit"></icon><span>Edytuj</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { format, parse } from "date-fns";
import pl from "date-fns/locale/pl";
import Multiselect from "vue-multiselect";

export default {
  props: {
    note: {
      type: Object,
      required: true,
    },
    users: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    Multiselect,
  },
  data() {
    return {
      isEdit: false,
      technicals: this.$props.users,
      order_note: {
        note: this.$props.note.note,
        service_date: new Date(this.$props.note.service_date),
        service_time_start: parse(
          this.$props.note.service_time_start,
          "HH:mm:ss",
          new Date()
        ),
        service_time_end: parse(
          this.$props.note.service_time_end,
          "HH:mm:ss",
          new Date()
        ),
        technical_ids: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      dictionary: "dictionary",
      user: "auth/user",
    }),
    noteTechnicals() {
      return this.$props.users.filter((user) =>
        this.$props.note.technical_ids.some((id) => id === user.id)
      );
    },
    filteredTechnicals() {
      return this.technicals.filter(
        (technical) => technical.id !== this.user.id
      );
    },
  },
  created() {
    this.technicals = this.$props.users;
    this.order_note.technical_ids = JSON.parse(
      JSON.stringify(this.noteTechnicals)
    );
  },
  methods: {
    ...mapActions({
      editOrderNote: "orders/editOrderNote",
      removeOrderNote: "orders/removeOrderNote",
    }),
    getDayName(date) {
      return format(new Date(date), "iiii", {
        locale: pl,
      });
    },
    formatWorkHour(hour) {
      return hour.slice(0, -3);
    },
    toggleEdit() {
      this.isEdit = !this.isEdit;
    },
    onOrderNoteSubmit() {
      const payload = {
        data: {
          ...this.order_note,
          service_date: format(
            new Date(this.order_note.service_date),
            "yyyy-MM-dd"
          ),
          service_time_start: format(
            new Date(this.order_note.service_time_start),
            "HH:mm:ss"
          ),
          service_time_end: format(
            new Date(this.order_note.service_time_end),
            "HH:mm:ss"
          ),
          technical_ids: this.order_note.technical_ids.map((item) => item.id),
          order_id: Number(this.$route.params.id),
        },
        id: this.$props.note.id,
      };

      this.editOrderNote(payload)
        .then(() => {
          this.$emit("update");
          this.toggleEdit();
        })
        .catch((error) => {
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    removeNote() {
      if (confirm("Czy na pewno?") === true) {
        this.removeOrderNote(this.$props.note.id)
          .then(() => {
            this.$emit("update");
          })
          .catch((error) => {
            this.$buefy.toast.open({
              duration: 5000,
              message: error.data.error_description,
              position: "is-bottom",
              type: "is-danger",
            });
          });
      }
    },
  },
};
</script>

<style scoped>
.note {
  margin: 20px 0;
}
.data {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
</style>
