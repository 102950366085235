import { render, staticRenderFns } from "./OrdersKanbanView.vue?vue&type=template&id=8c24702c&scoped=true&"
import script from "./OrdersKanbanView.vue?vue&type=script&lang=js&"
export * from "./OrdersKanbanView.vue?vue&type=script&lang=js&"
import style0 from "./OrdersKanbanView.vue?vue&type=style&index=0&id=8c24702c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8c24702c",
  null
  
)

export default component.exports